* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: 'Inter', sans-serif;
}

body {
  background: var(--background);
  color: var(--white);
}

:root {
  --white: #FFF;
  --black: #212121;
  --background: #024881;
  --text: #455a64;
  --yellow: #ce910f;
  --disabled: #B0BEC5;
  --button: #3DD2A2;
}
